import "./AboutStyles.css";

const aboutContent = [

  {
    heading: "The Ritual of Learning Emphasizes:",
    list: [
      "Continuous Growth: Learning is an evolving process that requires ongoing effort, reflection, and adaptation.",
      "Structured Guidance: Our expert tutors provide a clear framework to ensure students grasp fundamental concepts and master advanced topics.",
      "Commitment to Understanding: We prioritize comprehension and critical thinking over rote memorization.",
      "Building Strong Foundations: The Ritual of Learning nurtures discipline, perseverance, and curiosity."
    ]
  },
  {
    heading: "Key Principles:",
    list: [
      "Consistent Practice: Daily engagement and practice are essential for mastery.",
      "Holistic Development: Intellectual growth, personal development, and skill-building beyond academics."
    ]
  },
  {
    heading: "Why The Ritual of Learning Matters:",
    list: [
      "Empowering Students: Cultivating a mindset of continuous learning and personal responsibility.",
      "Fostering Passion for Learning: Encouraging curiosity and excitement in studies.",
      "Preparing for the Future: Equipping students with tools for success in academics and beyond."
    ]
  }
];
const programs = [
  { name: "Jupiter Junior", description: "Focused on foundational learning and school test preparation for Grades 5, 6, and 7." },
  { name: "Jupiter High", description: "Targeted guidance for Grade 8, 9, and 10 board exam preparation." },
  { name: "Jupiter Senior", description: "Comprehensive coaching for Grade 11 and 12 Commerce streams." },
  {
    name: (
      <>
        Jupiter Senior <sup style={{ fontSize: "0.7em" }}>plus</sup>
      </>
    ), description: "Specialized training for Grade 11 and 12 science students preparing for board exams and competitive exams like KCET, NEET, and JEE."
  }
];
function AboutUs() {
  return (
    <div className="about-container">

      <h1>Welcome to Jupiter Learning Centre</h1>
      <p>
        A trusted name in education led by faculty with over 20 years of experience in shaping young minds. Our institute is dedicated to providing exceptional coaching for students from Grades 5 to 12, spanning diverse streams including State, CBSE, and ICSE.
      </p>
      <p>
        At Jupiter Learning Centre, we believe every student has unique potential. With a combination of expert mentorship, innovative learning tools, and personalized attention, we empower students to achieve academic excellence and build confidence for the future.
      </p>
      <h2>Our Programs</h2>
      <ul>
        {programs.map((program, index) => (
          <li key={index}>
            <h3>{program.name}</h3>
            <p>{program.description}</p>
          </li>
        ))}
      </ul>
      <h2>Our Vision</h2>
      <p>At Jupiter Learning Centre, we believe that education is not just about acquiring knowledge but about creating a lifelong journey of discovery, growth, and mastery. Our vision, The Ritual of Learning, goes beyond a traditional approach to teaching; it embodies a mindset that fosters continuous learning, deep understanding, and consistent practice.</p>

      {aboutContent.map((section, index) => (
        <div key={index}>
          <h3>{section.heading}</h3>
          {section.text && <p>{section.text}</p>}
          {section.list && (
            <ul>
              {section.list.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      ))}
      <h2>Our Mision</h2>
      <p>
        Our mission is to inspire students to excel in academics while fostering critical thinking, problem solving, and a love for learning. Join Jupiter Learning Centre and embark on a journey towards a brighter and successful future!
      </p>
    </div>
  );
}

export default AboutUs;
