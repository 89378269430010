import React, { Component } from "react";
import "./AdvisorsStyles.css";

class AdvisorsData extends Component {
    render() {
        return (
            <div className={this.props.className}>
                <div className="adv-text">
                    <h2>{this.props.heading}</h2>
                    <h3>{this.props.heading2}</h3>
                    <p>{this.props.text}</p>
                </div>
                <div className="adv-image">
                    <img alt="advisor" src={this.props.img1} />
                </div>
            </div>
        );
    }
}

export default AdvisorsData;
