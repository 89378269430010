import "./ContactFormStyles.css";

function ContactForm() {
    return (
        <div className="form-container">
            <h1>Connect with Us!</h1>

            <p>For inquiries, feel free to reach us at:</p>
            <div className="phone-number">

                {/* <h3>
    <i className="fa-brands fa-square-whatsapp whatsapp-icon"></i>
    <a 
        href="tel:9108923646" 
        onClick={(e) => {
            if (!navigator.userAgent.match(/Android|iPhone|iPad|iPod/i)) {
                e.preventDefault();  // Prevents dialer opening on desktop
                window.open("https://web.whatsapp.com/send?phone=9108923646", "_blank");
            }
        }}
    >
        <span>9108923646</span>
    </a>
</h3> */}
                <h3>
                    <i className="fa-brands fa-square-whatsapp whatsapp-icon"></i>
                    <a href="https://wa.me/+919108923646" target="_blank" rel="noopener noreferrer">
                        <span>9108923646</span>
                    </a>
                </h3>
                <h3>
                    <i className="fa-brands fa-square-whatsapp whatsapp-icon"></i>
                    <a href="https://wa.me/+918095553366" target="_blank" rel="noopener noreferrer">
                        <span>8095553366</span>
                    </a>
                </h3>
                <h3>
                    <i className="fa-solid fa-envelope"></i>
                    <a
                        href="https://mail.google.com/mail/?view=cm&fs=1&to=manager@jupiterlc.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span>manager@jupiterlc.com</span>
                    </a>
                </h3>
            </div>
        </div>
    );
}

export default ContactForm;
