
import DestinationData from "./DestinationData";
import "./DestinationStyles.css"
import img1 from "../Assets/15.webp"
import img2 from "../Assets/16.webp"
import img3 from "../Assets/17.webp"
import img4 from "../Assets/20.webp"
import img5 from "../Assets/11.webp"
import img6 from "../Assets/8.webp"
import img7 from "../Assets/5.webp"
import img8 from "../Assets/13.webp"
const Destination = () => {
    return (
        <div className="destination">
            <h1>Our Programs</h1>
            <DestinationData
            className="first-des"
            heading ="Jupiter Junior"
            heading2 ="For Grades 5-7 | State, CBSE, ICSE"
            text="Designed for students in grades 5, 6, and 7, the Jupiter Junior program helps develop a strong academic foundation. With expert tutors and a personalized approach, we align our curriculum with your board's standards, ensuring seamless integration with school studies."
            img1={img1}
            img2={img2}
            />
            <DestinationData
            className="first-des-reverse"
            heading ="Jupiter High"
            heading2 = "For Grades 8-10 | State, CBSE, ICSE"
            text="Jupiter High is tailored for students in grades 8, 9, and 10, providing expert guidance in core subjects. Our curriculum aligns with your school’s board to build conceptual clarity and academic strength in preparation for your future exams."
            img1={img3}
            img2={img4}
            />
            <DestinationData
            className="first-des"
            heading ="Jupiter Senior"
            heading2 ="For Grades 11-12 Commerce | State, CBSE, ICSE"
            text="Jupiter Senior is designed for students in grades 11 and 12 pursuing commerce. We focus on conceptual clarity, analytical skills, and real-world application to ensure a comprehensive understanding of subjects like Accountancy, Economics, and Business Studies."
            img1={img7}
            img2={img8}
            
            />
            <DestinationData
            className="first-des-reverse"
            heading={<>Jupiter Senior <sup style={{ fontSize: "0.7rem" }}>plus</sup></>}
            // heading ="Jupiter Senior+"
            heading2 ="For Grades 11-12 Science | State, CBSE, ICSE"
            text={
                <>
                    Jupiter Senior <sup style={{ fontSize: '0.7em' }}>plus</sup> is the ideal program for grades 11 and 12 science students preparing for board exams and competitive exams like NEET, JEE, and KCET. Our expert faculty ensures in-depth learning in Physics, Chemistry, Mathematics, and Biology, guiding students toward success.
                </>
            }
            // text="Jupiter Senior Plus is the ideal program for grades 11 and 12 science students preparing for board exams and competitive exams like NEET, JEE, and KCET. Our expert faculty ensures in-depth learning in Physics, Chemistry, Mathematics, and Biology, guiding students toward success."
            img1={img5}
            img2={img6}
            />
        </div>
    )
}
export default Destination;