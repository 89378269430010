import Hero from "../components/Hero";
import Navbar from "../components/Navbar"
import AboutImg from "../Assets/9.webp"
import Footer from "../components/Footer";
import Content3 from "../components/Content3";
import Branches from "../components/Branches";
import FloatingButton from "../components/FloatingButton";

function Service(){
    return (
        <>
            <Navbar/>
       <Hero
   cName="hero-mid"
   heroImg={AboutImg}
   title="Branches"
   btnClass="hide"
   />
<Branches/>
<Content3/>
<FloatingButton/>
   <Footer/>
        </>
    )
    }
    
    export default Service;