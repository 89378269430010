import React from "react";
import AdvisorsData from "./AdvisorsData";
import "./AdvisorsStyles.css";
import img1 from "../Assets/ra.png";
import img3 from "../Assets/la.png";
import img5 from "../Assets/kp.png";
import img7 from "../Assets/ga.png";
import Img4 from "../Assets/Lb.png";
import img6 from "../Assets/ANM.png";
import img8 from "../Assets/KR.png";
import img2 from "../Assets/SA.png";

const Advisors = () => {
    return (
        <div className="advisors">
            <h1>Our Advisors</h1>
            <AdvisorsData
                className="adv-card"
                heading="Sri.Ranganath S."
                heading2="Academic Advisor"
                text="Sri Ranganath S. is a distinguished educator and a highly respected authority in the field of Physics education. With over 55 years of dedicated service, his contributions to teaching, content development, and curriculum design have shaped the academic journeys of countless students. He has been a guiding light for pre-university (PU) students, particularly in Physics, fostering a deep understanding of the subject and inspiring them to excel in board and competitive exams.
Sri Ranganath S.’s profound knowledge, extensive experience, and unwavering dedication to education make him an invaluable asset to Jupiter Learning Centre. As an Academic Advisor, he brings unparalleled expertise to the institution, ensuring that students receive the highest quality of education and guidance in Physics."
                img1={img1}
            />
            <AdvisorsData
                className="adv-card reverse"
                heading="Sri: S. Lakshminarayana Rao "
                heading2="Academic Advisor"
                text="With over 55 years of experience in Chemistry education, S. Lakshminarayana Rao has been a pillar of academic excellence and mentorship. He dedicated years of service as a lecturer at MES Degree College and Government Arts and Science College, shaping countless students and contributing to curriculum development. As an expert examiner, he set Karnataka’s Second PU Board Chemistry papers twice and designed KCET Chemistry question papers for five consecutive years. A distinguished author, his textbooks have helped students grasp complex concepts with clarity. As the head of Karnataka’s Teacher Training Committee, he played a crucial role in improving teaching methodologies. Now, as an Academic Advisor at Jupiter Learning Centre, he continues to guide and inspire the next generation of learners and educators."
                img1={img3}
            />
            <AdvisorsData
                className="adv-card"
                heading="Sri: D R Krishna Prasad "
                heading2="Academic Advisor"
                text="With 42 years of teaching experience in Mathematics, D. R. Krishna Prasad has held key academic roles, including Principal & Dean at KMWA (Deeksha Integrated) Pre-University College and Mathematics Lecturer at NMKRV and Vittal Pre-University Colleges. He has been actively involved in curriculum development, serving on various syllabus and examination committees, and has contributed as a faculty member for GRE, GMAT, and SAT coaching. As the founder of Ganitha Academy of Prasad (GAP), he has promoted Vedic Mathematics through workshops and free coaching for underprivileged students. Now, as the Academic Advisor at Jupiter Learning Centre, he continues to shape educational initiatives with his vast expertise."
                img1={img5}
            />
            <AdvisorsData
                className="adv-card reverse"
                heading= "Mrs.Girija Srinarayan"
                heading2="Academic Advisor"
                text="Mrs. Girija Srinarayan, an accomplished educator and researcher, has extensive experience in teaching Chemistry at various institutions, including AVK College, Kendriya Vidyalaya, and Sadvidya College. She has also contributed to research at MBM Engineering College and IIT Kharagpur, focusing on water purification and nanomaterials for fuel cells. Additionally, she has coached students for competitive exams like KCET, AIEEE, IIT JEE, and BITSAT and played a key role in content development for e-learning platforms. As the Academic Advisor at Jupiter Learning Centre, she leverages her vast expertise to enhance academic programs and mentor students for excellence."
                img1={img7}
            />
            <AdvisorsData
                className="adv-card"
                heading= "D. Litt. Bhushan N"
                heading2= "Academic Advisor | Visionary Educator | Mathematics Expert"
                text="D. Litt. Bhushan N is a distinguished scholar and academic leader dedicated to transforming education through strategic planning and innovative teaching. His contributions have earned him numerous prestigious awards, including the Pratibha Ratna Award (2011), Dronacharya Award (2016), Kempegowda Award (2017), and the Best Principal Award (2023). In 2024, he was ranked No.1 in India for Student Advancement & Mentoring and received multiple accolades for his excellence in education.
A pioneer in Mathematics education, he has revolutionized teaching methods, leading to outstanding SSLC results, including a 100% pass rate and multiple state-rank holders. His mentorship has helped students excel, with Bhavana T S securing the State First Rank (625/625) in 2023-24.
As Academic Advisor at Jupiter Learning Centre (JLC), he plays a key role in enhancing academic standards, training educators, and ensuring student success. His vision and dedication continue to inspire and shape the future of education."
                img1={Img4}
            />
            <AdvisorsData
                className="adv-card reverse"
                heading= "Ms. Anjali Jaganath"
                heading2="Principal | Educator | Teacher Trainer | Student & Parent Counsellor"
                text="Ms. Anjali Jaganath, an esteemed educator from Coorg, has over eight years of experience as Principal of KMWA Vidyaniketan, Bangalore, where she has driven academic excellence and holistic student development. Holding an MBA, M.A. in English, M.Ed., and a Diploma in Counselling Skills, she is also pursuing an M.Sc in Psychology from Jain University. A dedicated academic leader, she specializes in teacher training, curriculum enhancement, and student engagement, conducting workshops across Karnataka and serving as a Resource Person for English and Social Science. As a student and parent counselor, she provides guidance on academic success, emotional well-being, and career planning. At Jupiter Learning Centre (JLC), she mentors educators, refines teaching strategies, and ensures high academic standards. A former state-level badminton player, she is passionate about personality development, leadership, and communication skills, making a lasting impact on students and teachers alike."
                img1={img6}
            />
            <AdvisorsData
                className="adv-card"
                heading= "Dr. Krishna B.S"
                heading2="National Head – Commerce | Academic Scholar | Researcher | Educator | Academic Advisor"
                text="Dr. Krishna B.S is a distinguished academician, researcher, and educator with expertise in Commerce, Business Studies, and Management. As the National Head – Commerce, he plays a key role in academic strategy, curriculum development, and faculty training. He serves as an Academic Advisor at Jupiter Learning Centre (JLC), guiding faculty and students toward excellence. An accomplished author, he has written textbooks on Business Studies and Corporate Accounting, contributed to curriculum development, and designed examination papers. He conducts CA classes, mentors PhD scholars, and has presented extensive research at national and international conferences, earning accolades such as the Best Dean Award and Sambrama Sri Award. His philanthropic initiative, Jnana Surabhi Foundation, provides free education, textbooks, and personality development programs for underprivileged students. Beyond academia, he excels in swimming, bodybuilding, and music, having performed on Zee Kannada’s Comedy Kiladigalu. His visionary leadership continues to shape the future of education, empowering students and educators across India."
                img1={img8}
            />
            <AdvisorsData
                className="adv-card reverse"
                heading= "H. G. Sangappa"
                heading2="Senior Educator | Academic Consultant | Resource Person"
                text="With a distinguished 44-year teaching career, H. G. Sangappa has significantly contributed to Mathematics and Science education through curriculum development, teacher training, and academic leadership. He has played a vital role in textbook review, assessment design, and innovative teaching methodologies, making learning more engaging. As an organizer for high school question paper preparation at Sree Siddaganga Education Institutions for 15 years, he ensured academic rigor and fairness in examinations. A dedicated resource person and teacher trainer, he has conducted 25+ workshops on effective pedagogy and assessment strategies. At Jupiter Learning Centre (JLC), he mentors educators, enhances student performance strategies, and ensures excellence in curriculum execution. His lifelong dedication continues to shape both teachers and students, fostering analytical thinking and academic excellence."
                img1={img2}
            />
        </div>
    );
}

export default Advisors;
