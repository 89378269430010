import "./BranchesStyles.css"

const Branches = () =>{
    const branches = [
        {
          name: "Nandini Layout Branch",
          description:
            "Situated in the vibrant Nandini Layout area, our branch offers a peaceful, distraction-free atmosphere, perfect for students who wish to immerse themselves fully in the Ritual of Learning. The Ritual of Learning is incorporated into every class, ensuring that students build a disciplined study routine. With a team of dedicated educators, we provide expert coaching in a variety of subjects, from school-level curricula to entrance exam preparations. The modern infrastructure at our Nandini Layout branch creates a dynamic learning environment, helping students excel in both academics and beyond."
        },
        {
          name: "Hesaraghatta Branch",
          description:
            "Situated in the vibrant Hesaraghatta area, this branch serves as a hub for students looking for personalized academic support. Here, the focus is not just on teaching, but on developing the right mindset for success. Students are provided with personalized coaching for academic subjects and competitive exams, ensuring that each learning experience is aligned with their goals and aspirations. The serene surroundings of Hesaraghatta further enhance the learning process, enabling students to focus and perform at their best."
        }
      ];
       
  const features = [
    "Experienced Faculty: Our faculty members are not just knowledgeable in their fields but are also dedicated to creating an engaging and supportive learning experience.",
    "Personalized Attention: We believe that each student’s learning journey is unique. With a focus on individual attention, we ensure that every student receives the support and guidance they need to succeed.",
    "State-of-the-Art Facilities: Both branches are equipped with modern teaching aids, study materials, and comfortable classrooms that enhance the learning process.",
    "Supportive Environment: At Jupiter Learning Centre, we create a nurturing environment where students feel encouraged, motivated, and supported throughout their academic journey."
  ];
  return (
    
<div className="branches">
<h1>Empowering Students at Nandini Layout and Hesaraghatta</h1>
  <p>
    At Jupiter Learning Centre, we believe that every student has the potential to achieve excellence. With a strong commitment to providing quality education, we’ve cultivated a distinctive approach to learning—what we call the Ritual of Learning.
  </p>
  
  <h2>Our Vision: The Ritual of Learning</h2>
  <p>
    The Ritual of Learning is more than just a method; it is a mindset. We encourage students to approach education as a continuous, evolving journey through regular practice, structured guidance, and a deep commitment to understanding.
  </p>
  
  <h2>Branches</h2>
  {branches.map((branch, index) => (
    <div key={index} className="branch">
      <h3>{branch.name}</h3>
      <p>{branch.description}</p>
    </div>
  ))}
  
  <h2>Why Choose Jupiter Learning Centre?</h2>
  <ul>
    {features.map((feature, index) => (
      <li key={index}>{feature}</li>
    ))}
  </ul>
  
  <h2>Visit Us Today!</h2>
  <p>
    If you're looking to embrace the Ritual of Learning and excel in your academic and personal development, Jupiter Learning Centre at Nandini Layout and Hesaraghatta is the right place for you.
  </p>
</div>
)
}

export default Branches;