
import "./Testimonials.css";
import React, { useState, useEffect } from "react";

const thoughts = [
  "‘Jupiter Learning Centre made learning fun and easy. The teachers were supportive, and the practice tests helped me excel in my 10th state board exams. I’m grateful for their guidance!’",
  "‘Thanks to Jupiter Learning Centre, I gained confidence in my CBSE core subjects. Their innovative teaching methods and detailed notes were key to my success.’",
  "‘Jupiter Learning Centre’s personalized approach helped me overcome my struggles in Math and Science. Their guidance was a game-changer for my ICSE board exams.’",
  "‘The expert faculty at Jupiter Learning Centre ensured I was well-prepared for both board exams and KCET. Their mock tests and problem-solving sessions were outstanding!’",
  "‘I am very much satisfied with my KCET ranking. Thanks to the focused coaching at Jupiter Learning Centre. The balance between board prep and competitive training was perfect!’",
  "‘Jupiter Learning Centre’s commerce program simplified even the toughest concepts in Accountancy and Economics. Their dedication helped me achieve top marks.’",
  "‘The detailed explanations and practical approach at Jupiter Learning Centre gave me clarity and confidence in all my commerce subjects. Highly recommended!’",
  "‘The dedicated tutors at Jupiter Learning Centre made sure I understood every concept thoroughly. Their encouragement pushed me to achieve my best results in my 10th state board exam.’",
  "‘Jupiter Learning Centre provided me with excellent resources and one-on-one support, helping me perform well in my board exams. I owe my success to them!’",
  "‘The rigorous training at Jupiter Learning Centre prepared me for KCET and board exams simultaneously. Their guidance was instrumental in my success.’"
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % thoughts.length);
    }, 6000); // Change every 6 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="testimonials-container">
      <h2 className="testimonials-heading">Testimonials</h2>
      <div className="scroll-container">
        <div className="scroll-card">{thoughts[currentIndex]}</div>
      </div>
    </div>
  );
};

export default Testimonials;
