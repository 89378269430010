import Content3 from "../components/Content3";
import Destination from "../components/Destinate";
import Footer from "../components/Footer";
import HomeImg from "../Assets/22.webp"
import Hero from "../components/Hero";
import Navbar from "../components/Navbar"
import FloatingButton from "../components/FloatingButton";
import Testimonials from "../components/Testimonials";
function Home() {
    return (
        <>
            <Navbar />
            <Hero
                cName="hero"
                heroImg={HomeImg}
                title="Coaching Centre with Best in Class Fecilities"
                text="Exclusive School/College based Batches"
                btnText="Structure of Program"
                url="/"
                btnClass="hide"
            />
            <Destination />
            <Content3 />
            <Testimonials/>
            <FloatingButton/>
            <Footer />
        </>
    )
}

export default Home;