import Hero from "../components/Hero";
import Navbar from "../components/Navbar"
import AboutImg from "../Assets/16.webp"
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";
import Content3 from "../components/Content3";
import FloatingButton from "../components/FloatingButton";
import Advisors from "../components/Advisors";
function About(){
    return (
        <>
            <Navbar/>
   <Hero
   cName="hero-mid"
   heroImg={AboutImg}
   title="About Us"
   btnClass="hide"
   />
   <AboutUs/>
   <Advisors/>
   <Content3/>
   <FloatingButton/>
   <Footer/>
        </>
    )
    }
    
    export default About;