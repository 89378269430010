import React from 'react'
import Home from './routes/Home'
import About from './routes/About'
import Service from './routes/Service'
import Contact from './routes/Contact'
import {Route, Routes} from "react-router-dom"
import ScrollToTop from "./components/ScrollTop";

const App = () => {
  return (
    <div className='App'>
      <ScrollToTop /> 
<Routes>
  <Route path="/" element={<Home/>}/>
  <Route path="/about" element={<About/>}/>
  <Route path="/service" element={<Service/>}/>
  <Route path="/contact" element={<Contact/>}/>
</Routes>
   
 
    </div>
  )
}

export default App
