import { Component } from "react";
import "./NavbarStyles.css";
import { Link } from "react-router-dom";
import {MenuItems} from "./MenuItems"
import logo from "../Assets/jlcLogo.webp"

class Navbar extends Component {
    state = {clicked : false};
    handleClick = () =>{
        this.setState({clicked:!this.state.clicked})
    }
    render() {
        return (
            <nav className="NavbarItems">
<h1 className="navbar-logo">
    <img src={logo} alt="Logo" className="navbar-icon" />
    <div className="navbar-text">
        <span className="navbar-title">JUPITER LEARNING CENTRE</span>
        <p className="navbar-subtext">The Ritual of Learning</p>
    </div>
</h1>



               
                <div className="menu-icons" onClick={this.handleClick}>
                    <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
                </div>
                <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
                    {MenuItems.map((item,index) =>{
                        return(
                            <li key={index}>
                        <Link className= {item.cName} to={item.url}>
                            <i className={item.icon}></i>{item.title}
                        </Link>
                    </li>
                        )
                    })}
                  { /* <button>Sign Up</button>*/}
                </ul>

            </nav>
        );
    }
}

export default Navbar;