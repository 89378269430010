import React from "react";
import { Fab } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const FloatingButton = () => {
  return (
    <Fab
      color="success"
      aria-label="whatsapp"
      href="https://wa.me/+918095553366"
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        border: "2px solid white", // Stroke effect
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Slight shadow
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.1)", // Slight increase in size
          backgroundColor: "#25D366", // WhatsApp green
          borderColor: "#128C7E", // Darker green stroke on hover
          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.5)", // Deeper shadow
        },
      }}
    >
      <WhatsAppIcon style={{ fontSize: "30px" }} />
    </Fab>
  );
};

export default FloatingButton;
