import "./Content3Styles.css"
import Content3Data from "./Content3Data"
import cImg1 from "../Assets/7.webp"
import cImg2 from "../Assets/18.webp"
import cImg3 from "../Assets/19.webp"
import cImg4 from "../Assets/10.webp"
function Content3() {
    return(
        <div className="content3">
            <h1>Why Jupiter Learning Centre?</h1>
            <div className="contentCard">
                <Content3Data
               cImg2={cImg1}
                heading="Mentor Guidance"
                text="Guided by proven mentors,we turn challenges into achievements and dreams into realities"
                />
                <Content3Data
               cImg2={cImg2}
                heading="Expert Tutor"
                text="Experience unmatched guidance from expert educators dedicated to helping you achieve excellence."
                />
                <Content3Data
               cImg2={cImg3}
                heading="Personalized Attention"
                text="Our skilled tutor provide personalized attention turning your weaknesses into strengths effortlessly."
                />
                <Content3Data
               cImg2={cImg4}
                heading="Learning Tools"
                text="From interactive materials to advanced techniques our tools make mastering concepts a breeze."
                />
                
            </div>
        </div>
    )
}
export default Content3;