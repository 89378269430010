import "./FooterStyles.css"
import logo from "../Assets/jlcLogo.webp"

const Footer = () => {
    return (
        <div className="footer">
            <div className="top">
                <div>
                    <img src={logo} alt="Logo" className="navbar-icon" />
                </div>
                <div>
                    <a href="/">
                        <i className="fa-brands fa-facebook-square"></i>
                    </a>
                    <a href="https://wa.me/+918095553366" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-square-whatsapp"></i>
                    </a>
                    <a href="/">
                        <i className="fa-brands fa-instagram-square"></i>
                    </a>
                    <a href="/">
                        <i className="fa-brands fa-twitter-square"></i>
                    </a>
                </div>
            </div>
            <div className="bottom">

                <div>
                    <h4>Courses</h4>
                    <a href="/">Jupiter Junior</a>
                    <a href="/">Jupiter High</a>
                    <a href="/">Jupiter Senior</a>
                    <a href="/">{<>Jupiter Senior <sup style={{ fontSize: "0.7rem" }}>plus</sup></>}</a>
                </div>
                <div>
                    <div>


                        <h4>Nandhini Layout Branch</h4>
                        <p>#126 ,I main raoad Rajeevgandhinagar</p>
                        <p>Nandhini Layou</p>
                        <p>Bengaluru-96</p>
                    </div>


                </div>
                <div>
                    <h4>Hesaraghatta Branch</h4>
                    <p>#39 & 42,</p>
                    <p>H V Govindaraju Layout,</p>
                    <p>Near CONCORDE APARTMENT,</p>
                    <p>Born Mill bus stop</p>
                    <p>Hesaraghatta Main Road</p>
                    <p>T. Dasarahalli, Bengaluru-57</p>
                </div>

            </div>
            <footer className="footerRights">
                <p>© {new Date().getFullYear()} Jupiter Learning Centre. All rights reserved.</p>
            </footer>
        </div>
    )
}
export default Footer