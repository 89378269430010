import Hero from "../components/Hero";
import Navbar from "../components/Navbar"
import AboutImg from "../Assets/4.webp"
import Footer from "../components/Footer";
import ContactForm from "../components/ContctForm";
import FloatingButton from "../components/FloatingButton";
function Contact(){
    return (
        <>
             <Navbar/>
       <Hero
   cName="hero-mid"
   heroImg={AboutImg}
   title="Contact"
   btnClass="hide"
   />
   <ContactForm/>
   <FloatingButton/>
   <Footer/>
        </>
    )
    }
    
    export default Contact;