import "./Content3Styles.css"
import cImg2 from "../Assets/4.webp"
import cImg3 from "../Assets/3.webp"
function Content3Data(props){
return(
    <div className="c-card">
    <div className="c-image">
      <img src={props.cImg2} alt="image"/> 
    </div>
    <h4>{props.heading}</h4>
    <p>{props.text}</p>
</div>
)
}
export default Content3Data